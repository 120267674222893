import { getAuth } from '@clerk/nextjs/server';
import type { GetServerSideProps } from 'next';

type LoginProps = Record<string, never>;

const Login: React.FC<LoginProps> = () => {
  return null;
};

export const getServerSideProps: GetServerSideProps<LoginProps> = ({
  req,
  query,
}) => {
  const auth = getAuth(req);
  const { sessionClaims } = auth;

  if (sessionClaims?.external_id) {
    // intentionally not using req.query because the redirect contains a query string itself
    // if we use query it will split the redirect query params into separate query vars
    const queryParams = new URLSearchParams(query as Record<string, string>);
    const redirect = queryParams.get('redirect');

    return Promise.resolve({
      redirect: {
        destination: (redirect ?? '/') as string,
        permanent: false,
      },
    });
  }

  return Promise.resolve({
    props: {},
  });
};

export default Login;
